<template>
  <div>
    <div class="card card-padding">
      <el-form
          :model="createCustomReportForm"
          ref="createFeedbackFormRef"
          :rules="createCustomReportFormRules"
          class=" border-0 col-lg-10 m-auto px-xl-20 pt-5 pb-10">
        <div class="d-sm-flex justify-content-between gap-5">
          <div class="w-sm-50">
            <el-form-item class="mb-15 grey-field" prop="customer_ids">
              <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Select Customers") }}</label>
              <div class="cursor-pointer position-relative z-index-1" @click="dialogSelectCustomers = true">
                <el-select
                  class="position-relative"
                  style="z-index: -1"
                  v-model="createCustomReportForm.customer_ids"
                  :placeholder="$t('All Customers')">
                </el-select>
              </div>
              <SelectCustomers
                :popup-visible="dialogSelectCustomers"
                @handle-close="dialogSelectCustomers = false"
                :title="$t('Select Customers')"
                :subtitle="$t('Select Customer you want reports on')"
                :placeholder="$t('Search Customers')"
                :dataForSelect="bankCustomers"
                @handle-confirm="handleConfirmCustomers"
              />
            </el-form-item>
            <el-form-item class="mb-15 grey-field" prop="product_areas">
              <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Product Areas") }}</label>
              <div class="cursor-pointer position-relative z-index-1">
                <div @click="dialogProductAreaVisible = true" class="select-popup"></div>
                <el-select
                  class="position-relative"
                  style="z-index: -1"
                  disabled
                  v-model="createCustomReportForm.product_areas"
                  multiple
                  collapse-tags
                  @click="dialogProductAreaVisible = true"
                  :placeholder="$t('All Product Areas')">
                </el-select>
              </div>
              <div v-if="dialogProductAreaVisible">
                <SelectModal
                  :popupVisible="dialogProductAreaVisible"
                  :dataForSelect="product_areas"
                  :dataForUpdate="createCustomReportForm.product_areas"
                  :selfValue="true"
                  multiple="true"
                  @handle-close="dialogProductAreaVisible = false"
                  @handle-confirm="handleConfirmProductArea"
                />
              </div>
            </el-form-item>
            <el-form-item class="mb-15 grey-field" prop="countries">
              <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Countries") }}</label>
              <div class="cursor-pointer position-relative z-index-1" @click="dialogCountriesVisible = true">
                <el-select
                  class="position-relative"
                  style="z-index: -1"
                  disabled
                  multiple
                  v-model="createCustomReportForm.countries"
                  @click="dialogCountriesVisible = true"
                  :placeholder="$t('All Countries')">
                </el-select>
              </div>
              <div v-if="dialogCountriesVisible">
                <SelectModal
                  :multiple="true"
                  :popupVisible="dialogCountriesVisible"
                  :dataForSelect="countries"
                  :selfValue="true"
                  :title="$t('Select Country')"
                  :subtitle="$t('Select Country you want reports on')"
                  :placeholder="$t('Search Country')"
                  :dataForUpdate="createCustomReportForm.countries"
                  @handle-close="dialogCountriesVisible = false"
                  @handle-confirm="handleConfirmCountries"
                />
              </div>
            </el-form-item>
          </div>
          <div class="w-sm-50">
            <el-form-item class="mb-15 grey-field" prop="tiers">
              <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Segment") }}</label>
              <el-select
                v-model="createCustomReportForm.tiers"
                filterable
                :placeholder="$t('All Tiers')"
                multiple
              >
                <el-option
                  v-for="(item,index) in tiers"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="mb-15 grey-field" prop="time_period">
              <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Time Period") }}</label>
              <el-date-picker
                v-model="createCustomReportForm.time_period"
                type="daterange"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
                start-placeholder="Start Date"
                end-placeholder="End Date"
                :disabled-date="disabledDate"
              />
            </el-form-item>
            <el-form-item class="mb-15 grey-field" prop="industries">
              <label class="d-block fs-3 text-gray-700 mb-3">{{ $t("Industry") }}</label>
              <el-select
                multiple
                v-model="createCustomReportForm.industries"
                :placeholder="$t('Select Industry')">
                <el-option
                  v-for="(industry, index) in industries"
                  :key="index"
                  :label="industry"
                  :value="industry"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <hr />
        <div class="d-sm-flex justify-content-between gap-5">
          <div class="w-sm-50">
            <div class="my-4 d-flex justify-content-between align-items-center w-100">
              <label class="fw-bold fs-4 mb-0">{{ $t("Include Requests in Report") }}</label>
              <el-form-item class="mb-0 ">
                <el-switch v-model="createCustomReportForm.include_requests" />
              </el-form-item>
            </div>
          </div>
          <div class="w-sm-50">
            <div class="my-4 d-flex justify-content-between align-items-center w-100">
              <label class="fw-bold fs-4 mb-0">{{ $t("Include Ideas in Report") }}</label>
              <el-form-item class="mb-0 ">
                <el-switch v-model="createCustomReportForm.include_ideas" />
              </el-form-item>
            </div>
          </div>
        </div>
        <hr />
      </el-form>
      <button class="btn main-btn d-block mx-auto w-lg-25" :data-kt-indicator="buttonIndicator" :disabled="buttonIndicator === 'on'" @click="generateReport">
        <span class="indicator-label">{{ $t("Generate Report") }}</span>

        <span class="indicator-progress">
        {{ $t("pleaseWait") }}
        <span
            class="spinner-border spinner-border-sm align-middle ms-2"
        ></span>
      </span>
      </button>
    </div>

    <div class="card card-padding arriving-soon-card mt-5" :class="{'show-arriving': showSuccessMessage}">
      <div class="arriving-soon-card__content">
        <div class="arriving-soon-card__img">
          <img src="/media/buying/icons/undraw_data_processing_yrrv.svg" alt="">
          <span>{{ $t('Arriving Soon...') }}</span>
        </div>
        <div class="arriving-soon-card__text">
          <h4>{{ $t('We are now generating your report. Please wait a few minutes.') }}</h4>
          <p>
            {{ $t('Generated Report will be sent on') }}
            <b>{{ userInfo.email }}</b>
            {{ $t('as a link once done.') }}
          </p>
        </div>
      </div>
      <div class="arriving-soon-card__hide">
        <button @click="showSuccessMessage = false">{{ $t('Hide') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";
import store from "@/store";
import moment from "moment";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import SelectCustomers from "@/buying-teams/shared-components/modals/SelectCustomers";

export default {
  name: "CustomReports",

  components: {
    SelectModal,
    SelectCustomers
  },

  props: [],

  emits: [],
  computed: {
    product_areas() {
      return store.getters.businessProductAreas;
    },
    bankCustomers() {
      return [
        {
          id: 1,
          name: "Kaasa"
        },
        {
          id: 2,
          name: "Koldplay Incorporations"
        }
      ];
    },
    countries() {
      return store.getters.businessCountries;
    },
    businessCreateDate() {
      return store.getters.bank.created_at;
    },
    userInfo() {
      return store.getters.currentUser
    },
    industries() {
      return store.getters.allIndustries;
    },
    tiers() {
      return [
        {
          label: this.$t('All'),
          value: 'all'
        },
        ...store.getters.tierList
      ]
    },
  },

  data() {
    return {
      buttonIndicator: 'off',
      dialogProductAreaVisible: false,
      dialogSelectCustomers: false,
      dialogCountriesVisible: false,
      customersValue: '',
      showSuccessMessage: false,
      createCustomReportForm: {
        time_period: [],
        customer_ids: '',
        countries: [],
        product_areas: [],
        include_requests: false,
        include_ideas: false,
        tiers: [],
        industries: [],
      },
      createCustomReportFormRules: {
        customer_ids: [
          { required: true, message: this.$t("Select Customers"), trigger: "change" }
        ],
        countries: [
          { required: true, message: this.$t("Please select Countries"), trigger: "change" }
        ],
        tiers: [
          { required: true, message: this.$t("Please select Tiers"), trigger: "change" }
        ],
        product_areas: [
          { required: true, message: this.$t("Please input Product Area"), trigger: "change" }
        ],
        time_period: [
          { required: true, message: this.$t("Please select Time Period"), trigger: "change" }
        ]
      }
    };
  },

  methods: {
    setSelectedCustomersValue(){
      this.customersValue = [];
      this.bankCustomers.map(item => {
        if (item.id === this.createCustomReportForm.customer_ids) {
          this.customersValue.push(item.name);
        }
      });
    },
    handleConfirmProductArea(event) {
      this.createCustomReportForm.product_areas = event;
      this.dialogProductAreaVisible = false;
    },
    handleConfirmCustomers(event) {
      this.createCustomReportForm.customer_ids = event;
      this.setSelectedCustomersValue();
    },
    handleConfirmCountries(event) {
      this.createCustomReportForm.countries = event;
      this.dialogCountriesVisible = false;
    },
    generateReport() {
      this.showSuccessMessage = false;
      this.$refs.createFeedbackFormRef.validate(valid => {
        if (valid) {
          this.buttonIndicator = 'on';
          // store.dispatch('generateReport', this.createCustomReportForm)
          //   .then((res) => {
          //     if (res.status === 200) {
          //       this.showSuccessMessage = true;
          //       this.$nextTick(() => {
          //         window.scrollTo({
          //           top: document.body.scrollHeight || document.documentElement.scrollHeight,
          //           behavior: 'smooth'
          //         });
          //       })
          //     }
          //   })
          //   .catch((err) => {
          //     NotificationService.swalError(err);
          //     this.showSuccessMessage = false;
          //   })
          //   .finally(() => {
          //     this.buttonIndicator = 'off';
          //   });
        }
      });
    },
    disabledDate(time) {
      return time.getTime() > Date.now() || time.getTime() < new Date(this.businessCreateDate);
    },
  },

  mounted() {
    setCurrentPageBreadcrumbs({
      title: this.$t('Custom Reports'),
      subTitle: this.$t('Please define the content of your Report'),
    })
  },

  created() {
    this.$nextTick(() => {
      this.createCustomReportForm.customer_ids = this.bankCustomers.map(val => val.id)
      this.createCustomReportForm.product_areas = this.product_areas
      this.createCustomReportForm.countries = this.countries
      this.createCustomReportForm.time_period = [
        moment(this.businessCreateDate).format('YYYY/MM/DD'),
        moment().format('YYYY/MM/DD')
      ]
      this.customersValue = this.bankCustomers.map(val => val.name)
      this.setSelectedCustomersValue();
    })
  }
};
</script>
<style lang="scss">
.arriving-soon-card {
  padding: 16px 30px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  display: none;
  &.show-arriving {
    display: flex;
  }
  &__img {
    display: flex;
    align-items: center;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.24);
      margin-left: 9px;
    }
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__text {
    margin-left: 24px;
    padding-left: 22px;
    border-left: 1px solid #DDDDDD;
    h4 {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #434343;
    }
    p {
      margin-bottom: 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 23px;
      color: #434343;
      b {
        color: #435BF4;
      }
    }
  }
  &__hide {
    button {
      background: transparent;
      padding: 0;
      border: none;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #E22D21;
    }
  }
}

@media (max-width: 1200px) {
  .arriving-soon-card {
    &__content {
      flex-direction: column;
      align-items: center;
    }
    &__text {
      margin-left: 0;
      padding-left: 0;
      border-left: none;
    }
    &__img {
      margin-bottom: 20px;
      padding-bottom: 24px;
      border-bottom: 1px solid #DDDDDD;
    }
  }
}
</style>
