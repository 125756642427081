<template>
  <el-dialog
      v-model="popupVisible"
      :width="width"
      center
      :show-close="true"
      @close="$emit('handle-close')"
      custom-class="select-customers"
  >
    <div class="row">
      <div class="col-md-5 select-customers__border-end">
        <div class="px-7">
          <div class="select-customers__form-item">
            <label>{{ $t('Select Tier') }}</label>
            <el-radio-group class="priority" v-model="filter.tier">
              <el-radio-button v-for="(item, index) in getTierList" :key="index" :label="item.value" name="priority">
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </div>

          <div class="select-customers__form-item mt-7">
            <label>{{ $t('Select Industry') }}</label>
            <el-select class="selectProductArea" v-model="filter.industry" :placeholder="$t('Select Industry')">
              <el-option
                  v-for="item in industryList"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </div>

          <div class="select-customers__form-item mt-7">
            <label>{{ $t('Product Areas') }}</label>
            <el-select class="selectProductArea" v-model="filter.product_areas" :placeholder="$t('Product Areas')">
              <el-option
                  v-for="item in productAreasList"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </div>

          <div class="select-customers__form-item mt-12 d-flex align-items-center mb-5 justify-content-between">
            <label class="select-box-label">{{ $t("You can select a repeat frequency") }}</label>
            <el-switch v-model="filter.followed"/>
          </div>

          <div class="select-customers__form-item mt-13">
            <label>{{ $t('Country of Global Head Office') }}</label>
            <el-select class="selectProductArea" v-model="filter.product_areas" :placeholder="$t('Product Areas')">
              <el-option
                  v-for="item in productAreasList"
                  :key="item"
                  :label="item"
                  :value="item"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div class="col-md-7">
        <div class="mx-7">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="select-modal__header d-flex align-items-center flex-wrap mb-3">
                <h1 class="fw-bolder me-5">{{ title ? title : $t("Select") }}</h1>
              </div>
              <p class="text-gray mb-5">{{ subtitle ? subtitle : $t("Select") }}</p>
            </div>
            <div class="search-input">
              <label>
                <img src="/media/buying/icons/search.svg" alt="">
              </label>
              <el-input
                class="search-input"
                v-model="input"
                type="text"
                :placeholder="placeholder ? placeholder : $t('Search')"
                @input="searchData($event)"
              />
            </div>
          </div>

          <div class="py-5 overflow-auto mh-470">
            <el-checkbox-group
              v-model="selected"
            >
              <div class="checkboxLabel">
                <el-checkbox
                  :label="$t('All Customers')"
                  class="customCheckbox"
                  @change="handleSelect"
                  size="large">
                  <span class="circleImg"></span>
                  {{ $t('All Customers') }}
                </el-checkbox>
              </div>
              <div
                v-for="(item, i) in options"
                :key="i"
                class="checkboxLabel"
              >
                <el-checkbox
                  :label="selfValue ? item : item.id"
                  class="customCheckbox"
                  @change="handleSelect"
                  size="large">
                  <span class="circleImg"></span>
                  {{ selfValue ? item : item.name }}
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-10 px-7">
      <div class="applied-filters d-flex align-items-center">
        <div class="applied-filters__label">
          {{ $t('filters applied', {n: 2}) }}
        </div>
        <div class="applied-filters__btn">
          {{ $t('Clear all') }}
        </div>
      </div>
      <span @click="confirmPopup" class="main-btn btn px-20 d-block">
        {{ $t("Continue") }}
      </span>
    </div>
  </el-dialog>
</template>

<script>
import store from "@/store";

export default {
  name: "SelectCustomers",
  emits: ["handle-close", "handle-confirm"],
  props: {
    popupVisible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '1158px'
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    dataForSelect: {
      type: Array,
      default: () => []
    },
    selfValue: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    filter: {
      tier: 'all',
      industry: '',
      product_areas: '',
      followed: false,
    },
    input: "",
    selectAll: false,
    selected: [],
    singleSelected: '',
    options: [],
  }),
  created() {
    this.options = [
      ...this.dataForSelect
    ]
  },
  computed: {
    getTierList() {
      return [
        {
          label: this.$t('All'),
          value: 'all'
        },
        ...store.getters.tierList
      ]
    },
    industryList() {
      return [
        'Information Technology'
      ]
    },
    productAreasList() {
      return [
        'Cash Management'
      ]
    },
    isAllSelected() {
      return this.selected.length === this.options.length;
    }
  },
  methods: {
    handleSelect() {
      this.selectAll = this.isAllSelected;
    },

    selectAllMultiple(){
      if(!this.selectAll){
        this.options.map((item, i) => {
          this.selected[i] = this.selfValue ? item : item.id
        })
      }else{
        this.selected = []
      }
    },

    confirmPopup() {
      this.$emit("handle-confirm", this.selected);
      this.$emit("handle-close");
    },

    searchData(event) {
      if (event !== "") {
        this.options = this.dataForSelect.filter(item => {
          return item.name.toLowerCase().includes(event);
        });
      } else {
        this.options = this.dataForSelect;
      }
    }
  }
}
</script>

<style lang="scss">
.select-customers {
  .el-dialog {
    &__header {
      padding: 0;
    }

    &__headerbtn {
      display: flex;
    }
  }

  &__form-item {
    > label {
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      color: #4F4F4F;

      &.select-box-label {
        font-weight: 400;
        font-size: 16px;
        line-height: 158.02%;
        color: #2B2B2B;
      }

    }
  }

  &__border-end {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
  }

  .search-input {
    input {
      background: #FAFAFA;
      border: none;
      border-radius: 29px;
      height: 50px;
      padding: 0 0 0 40px;
    }
  }

  .mh-470 {
    max-height: 470px;
  }

  .applied-filters {
    &__label {
      background: rgba(82, 82, 82, 0.04);
      border-radius: 50px;
      padding: 5px 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 158.02%;
      text-align: center;
      color: #BCBCBC;
    }
    &__btn {
      font-weight: 600;
      font-size: 16px;
      line-height: 158.02%;
      color: #435BF4;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
